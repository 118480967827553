import React from "react";
import Function from "./components/function";
import './App.css'
import 'bootstrap/dist/css/bootstrap.css';

const App = () => {
  

  return (
    <div className="App container-fluid">
      <Function/>
    </div>
  );
};

export default App;